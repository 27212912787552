import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import Imgix from 'shared/components/imgix';
import { Button } from 'components/core';
import { CheckIcon } from 'src/assets/check';
import { useOpenDutchiePay } from 'src/payments/hooks/use-open-dutchie-pay/use-open-dutchie-pay';
import { BankIconOutline } from 'assets/bank-icon-outline';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function DutchiePaySection(): JSX.Element {
  const { t, Trans } = useTranslation();
  const flags = useFlags();
  const { openDutchiePay } = useOpenDutchiePay({
    cname: '',
    enrollmentSource: 'dutchie-homepage',
  });
  const isDutchiePayBanner = flags['growth.ecomm.dutchie-pay-admin-banner.rollout'] ?? false;

  return (
    <Section container>
      <LeftColumn>
        <Content data-ga-label='dutchie-pay-homepage'>
          {isDutchiePayBanner && (
            <DutchiePayBannerContainer href='https://business.dutchie.com/payments?utm_medium=in-app&utm_source=DPOS_product&utm_campaign=base_login_page_DP_PBB&utm_content=base_base_link'>
              <BankIconOutline width={30} height={30} />
              <div>
                <DutchiePayBannerTextHeader>
                  Dutchie Pay is now Pay by Bank, powered by Plaid
                </DutchiePayBannerTextHeader>
                <DutchiePayBannerTextBody>
                  Instant bank payments from the cannabis industry leader. <b>Learn More</b>
                </DutchiePayBannerTextBody>
              </div>
            </DutchiePayBannerContainer>
          )}
          <SectionHeading>{t('home.dutchiePay', 'Want an easier way to pay? Choose Dutchie Pay.')}</SectionHeading>
          <P>
            <Trans i18nKey='home.dutchiePayLetsYouConnect'>
              Dutchie Pay lets you connect your bank account and make secure, cashless payments with just a few clicks.
              For shopping online or in-store, skip the ATM and enjoy the ease of Dutchie Pay.
            </Trans>
          </P>

          <List>
            <ListItem>
              <CheckIcon width={24} height={24} />
              {t('home.dutchiePayListItem', 'Get started in just a few quick steps')}
            </ListItem>
            <ListItem>
              <CheckIcon width={24} height={24} />
              {t('home.dutchiePayListItem', 'Securely connect your bank')}
            </ListItem>
            <ListItem>
              <CheckIcon width={24} height={24} />
              {t('home.dutchiePayListItem', 'Use your Dutchie Pay account at any participating dispensary')}
            </ListItem>
          </List>

          <StyledButton size='medium' onClick={openDutchiePay}>
            {t('home.signup', 'Sign up')}
          </StyledButton>
        </Content>
      </LeftColumn>
      <ImageContainer>
        <DutchiePayImage
          htmlAttributes={{ alt: t('home.dutchiePay', 'Dutchie Pay') }}
          src='https://images.dutchie.com/pay/dutchie-pay-homepage-img.png'
        />
      </ImageContainer>
    </Section>
  );
}

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.dutchiePayGreen};
  text-transform: none;
  font-weight: 600;
  font-size: 15px;
`;

const Content = styled.div`
  max-width: 560px;
  margin: 0 auto;
`;

const SectionHeading = styled.h2`
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    font-size: 28px;
    line-height: 120%;
  }
`;

const DutchiePayBannerContainer = styled.a`
  align-items: center;
  background-color: #00a47c;
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  max-width: fit-content;
  padding: 1rem;
`;

const DutchiePayBannerTextHeader = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0.5rem;
`;

const DutchiePayBannerTextBody = styled.div`
  color: #ffffff;
  font-size: 12px;

  span {
    color: #ffffff;
    font-weight: 700;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  min-height: 269px;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 600px;
`;

const LeftColumn = styled.div`
  color: #171a1c;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding: 50px 34px;
    width: 100%;
  }
`;

const P = styled.p`
  font-size: 18px;
  line-height: 32px;
  margin: 18px 0 0;

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    font-size: 13px;
    line-height: 180%;
  }
`;

const Section = styled.section`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 360px;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 100px;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    flex-direction: column;
    margin: 0 auto;
  }
`;

const DutchiePayImage = styled(Imgix)`
  width: 100%;
`;

const List = styled.ul`
  list-style: none;
  margin: 48px 0 56px;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2e3538;

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    font-size: 13px;
    margin: 30px 0 48px;
  }
`;

const ListItem = styled.li`
  align-items: start;
  display: flex;
  margin: 12px 0;

  svg {
    flex-shrink: 0;
    padding-right: 6px;
  }

  path {
    stroke: #171a1c;
  }
`;
